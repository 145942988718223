import { AUTH_GUARD } from '@zeiss/ng-vis-vp-auth/injection-tokens';
import { RULE } from '@zeiss/ng-vis-vp-auth/constants';
import { vpRule } from '@zeiss/ng-vis-vp-auth/types';
import { PendingChangesGuard } from '@zeiss/ng-vis-mddb/guards';
const MDDB_PATHS = {
  _: 'mddb',
  meta: 'meta',
  view: 'view',
  edit: 'edit',
  create: 'create',
  details: 'details',
  master: 'master',
  object: 'object',
  operatingData: 'operatingdata',
  maintain: 'maintain',
  qrCode: 'qrcode',
  qrCodeScan: 'scan',
  wiki: 'wiki',
  pcpObject: 'object/pcp',
  maintenanceObject: 'object/maintenance',
  objectrelationView: 'objectrelationship/view',
  objectrelationEdit: 'objectrelationship/edit',
  objectrelation: 'objectrelationship',
  history: 'history',
  print: 'print',
  checklist: 'checklist'
};
const MDDB_ROUTES = {
  path: MDDB_PATHS._,
  canActivate: [AUTH_GUARD],
  data: {
    title: 'vp.mddb.routes.title',
    description: 'vp.mddb.routes.desc',
    icon: 'zui-icon-virtual-measuring-room',
    showInMenu: true,
    rule: vpRule({
      rule: RULE.access_mddb,
      required: []
    }),
    routes: [{
      path: ''
    }, {
      path: MDDB_PATHS.master,
      canActivate: [AUTH_GUARD],
      canDeactivate: [PendingChangesGuard],
      data: {
        title: 'vp.mddb.routes.master.title',
        description: 'vp.mddb.routes.master.desc',
        icon: 'zui-icon-square',
        showInMenu: true,
        cache: false,
        hierarchy: 40,
        rule: vpRule({
          rule: RULE.access_mddb,
          required: ['master']
        })
      }
    }, {
      path: MDDB_PATHS.object,
      canActivate: [AUTH_GUARD],
      canDeactivate: [PendingChangesGuard],
      data: {
        title: 'vp.mddb.routes.object.title',
        description: 'vp.mddb.routes.object.desc',
        icon: 'zui-icon-multiphase',
        showInMenu: true,
        cache: false,
        hierarchy: 30,
        rule: vpRule({
          rule: RULE.access_mddb,
          required: ['object']
        })
      }
    }, {
      path: MDDB_PATHS.meta,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'vp.mddb.routes.meta.title',
        description: 'vp.mddb.routes.meta.desc',
        icon: 'zui-icon-system-parameters',
        showInMenu: true,
        cache: false,
        hierarchy: 20,
        rule: vpRule({
          rule: RULE.access_mddb,
          required: ['meta']
        })
      }
    }, {
      path: `${MDDB_PATHS.operatingData}/${MDDB_PATHS.maintain}`,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'vp.mddb.routes.operatingData.title',
        description: 'vp.mddb.routes.operatingData.desc',
        icon: 'zui-icon-property-values',
        showInMenu: true,
        cache: false,
        hierarchy: 10,
        rule: vpRule({
          rule: RULE.access_mddb,
          required: ['operatingdata']
        })
      }
    }, {
      path: MDDB_PATHS.qrCode,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'vp.mddb.routes.qrCode.title',
        description: 'vp.mddb.routes.qrCode.desc',
        showInMenu: false,
        cache: false,
        rule: vpRule({
          rule: RULE.access_mddb,
          required: ['qrcode']
        })
      }
    }, {
      path: MDDB_PATHS.qrCodeScan,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'vp.mddb.routes.qrCodeScan.title',
        description: 'vp.mddb.routes.qrCodeScan.desc',
        icon: 'zui-icon-qr-code',
        showInMenu: true,
        cache: false,
        rule: vpRule({
          rule: RULE.access_mddb,
          required: ['qrcode']
        })
      }
    }, {
      path: MDDB_PATHS.wiki,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'vp.mddb.routes.wiki.title',
        description: 'vp.mddb.routes.wiki.desc',
        showInMenu: true,
        icon: 'zui-icon-file-file-portrait',
        cache: true,
        rule: vpRule({
          rule: RULE.access_mddb,
          required: ['wiki']
        })
      }
    }, {
      path: MDDB_PATHS.objectrelation,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'Object Relationship',
        showInMenu: false,
        cache: false,
        rule: vpRule({
          rule: RULE.access_mddb,
          required: ['objectrelationship']
        })
      }
    }, {
      path: MDDB_PATHS.objectrelationView,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'Object Relationship',
        showInMenu: false,
        cache: false,
        rule: vpRule({
          rule: RULE.access_mddb,
          required: ['objectrelationship']
        })
      }
    }, {
      path: MDDB_PATHS.objectrelationEdit,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'Object Relationship Edit',
        showInMenu: false,
        cache: false,
        rule: vpRule({
          rule: RULE.mddb_objectrelationship_functions,
          required: ['can_edit']
        })
      }
    }, {
      path: MDDB_PATHS.history,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'History',
        showInMenu: false,
        cache: false,
        rule: vpRule({
          rule: RULE.access_mddb,
          required: ['history']
        })
      }
    }, {
      path: MDDB_PATHS.print,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'vp.mddb.routes.print.title',
        description: 'vp.mddb.routes.print.desc',
        icon: 'zui-icon-print-print-default',
        showInMenu: true,
        cache: false,
        rule: vpRule({
          rule: RULE.access_mddb,
          required: ['object']
        })
      }
    }, {
      path: MDDB_PATHS.checklist,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'Checklist',
        showInMenu: false,
        cache: false,
        rule: vpRule({
          rule: RULE.access_mddb,
          required: ['object']
        })
      }
    }]
  }
};

/*
 * Public API Surface of ng-vis-omr/external
 */

/**
 * Generated bundle index. Do not edit.
 */

export { MDDB_PATHS, MDDB_ROUTES };
