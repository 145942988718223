import { OAuthModule } from 'angular-oauth2-oidc';
import { BehaviorSubject, filter } from 'rxjs';
import { Environment } from 'src/environments/environment';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import {
	GLOBAL_SEARCH_DATA_PROVIDER,
	GlobalSearchDataService,
	GlobalSearchModule,
	NgVisToastModule,
} from '@zeiss/ng-vis-common/components';
import { ENVIRONMENT$, LOCALE, SETTINGS_SERVICE, TASK_API_SERVICE } from '@zeiss/ng-vis-common/injection-tokens';
import { StorageSettingsService } from '@zeiss/ng-vis-common/services';
import { SystemLanguage } from '@zeiss/ng-vis-common/types';
import { NgVisTutorialModule } from '@zeiss/ng-vis-tutorial';
import { VpAuthModule } from '@zeiss/ng-vis-vp-auth';
import { VpAuthStateComponent } from '@zeiss/ng-vis-vp-auth/components';
import { App } from '../environments/shared';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SplashComponent } from './areas/splash/splash.component';
import { AccessFaqDialogComponent } from './common/access-faq-dialog/access-faq-dialog.component';
import { ApplicationInsightsService } from './common/application-insights/application-insights.service';
import { PendingChangesGuard } from './common/change-guard/change-guard';
import { HeaderbarComponent } from './common/headerbar/headerbar.component';
import { MenubarComponent } from './common/menubar/menubar.component';
import { CacheRouteReuseStrategy } from './common/router/route.strategy';
import { TaskApiService } from './common/task/task-api.service';
import { EventModule } from './shared/event/event.module';
import { GlobalImportsModule } from './shared/global-imports.module';
import { SharedInjectionsModule } from './shared/shared-injections.module';
import { MdmEsbSubscriptionKeyInterceptor } from '@zeiss/ng-vis-vp-auth/interceptors';
import { LanguageService } from './common/language/language.service';
import { NgVisAuthSettings, VP_SETTINGS } from '@zeiss/ng-vis-vp-auth/types';
import { VisTranslocoRootModule } from '@zeiss/ng-vis-common/i18n';
import { vpBlobOrigin, vpBlobPath } from '@zeiss/ng-vis-vp-common/constants';

import defaultTranslations from './i18n/translations.json';
import { TranslocoService } from '@jsverse/transloco';

let locale$ = new BehaviorSubject<SystemLanguage>(App.DefaultLanguage);
let env$ = new BehaviorSubject(Environment.AppMeta.Environment);

export function initializeApp(languageService: LanguageService): () => void {
	return () => {
		languageService.initLanguageSettings();
		console.info(`[APP_INITIALIZER] LanguageService initialized: Selected language is ${languageService.Language}`);
	};
}

@NgModule({
	declarations: [AppComponent, HeaderbarComponent, MenubarComponent, SplashComponent],
	imports: [
		AccessFaqDialogComponent,
		VpAuthModule,
		SharedInjectionsModule,
		BrowserModule,
		AppRoutingModule,
		OAuthModule.forRoot(),
		GlobalImportsModule,
		BrowserAnimationsModule,
		MatMenuModule,
		MatDialogModule,
		HttpClientModule,
		VisTranslocoRootModule.forRoot({
			root: {
				encapsulateByKey: 'vp',
				defaultTranslations,
				blob: {
					origin: vpBlobOrigin,
					path: `${vpBlobPath}/vp`,
				},
			},
		}),
		NgVisTutorialModule.forRoot({
			environment: Environment.AppMeta.Environment,
		}),
		NgxsModule.forRoot(),
		NgxsRouterPluginModule.forRoot(),
		NgxsReduxDevtoolsPluginModule.forRoot(),
		EventModule,
		NgVisToastModule,
		VpAuthStateComponent,
		GlobalSearchModule,
	],
	providers: [
		ApplicationInsightsService,
		{
			provide: APP_INITIALIZER,
			useFactory: initializeApp,
			deps: [LanguageService, TranslocoService],
			multi: true,
		},
		{ provide: ENVIRONMENT$, useValue: env$ },
		{ provide: RouteReuseStrategy, useClass: CacheRouteReuseStrategy },
		{ provide: TASK_API_SERVICE, useClass: TaskApiService },
		{ provide: LOCALE, useValue: locale$ },
		{
			provide: SETTINGS_SERVICE,
			useValue: new StorageSettingsService<NgVisAuthSettings>('VP_SETTINGS', VP_SETTINGS, localStorage),
		},
		PendingChangesGuard,
		{ provide: HTTP_INTERCEPTORS, multi: true, useClass: MdmEsbSubscriptionKeyInterceptor }, // used for the task api
		{ provide: GLOBAL_SEARCH_DATA_PROVIDER, useClass: GlobalSearchDataService },
	],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
	constructor(private transloco: TranslocoService, private languageService: LanguageService) {
		console.log(transloco);
		this.languageService.Language$.pipe(filter((locale) => !!locale)).subscribe((locale) => {
			const lang = locale ?? SystemLanguage['en-GB'];
			this.transloco.setActiveLang(lang);
			locale$.next(lang);
		});
	}
}
