import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalImportsModule } from '../global-imports.module';
import { EventComponent } from './event.component';
import { TranslocoModule } from '@jsverse/transloco';

@NgModule({
	declarations: [EventComponent],
	imports: [CommonModule, GlobalImportsModule, TranslocoModule],
	exports: [EventComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class EventModule {}
