<ng-container *ngIf="routeConfigMain.length > 0">
	<zui-menubar
		[visTutorial]="{
			topic: tutorialTopics.General,
			title: 'Navigation bar',
			text:
				'The navigation bar contains all the different areas of the <b>' +
				app.Name1 +
				'</b> ' +
				app.Name2 +
				' you currently have access to.',
			options: {
				order: -890
			}
		}"
		vertical
		variant="primary"
		#menubar
	>
		<ng-container *ngFor="let link of routeConfigMain; index as i">
			<zui-menubar-nav-item
				routerLinkActive
				[routerLinkActiveOptions]="{
					exact: false
				}"
				#rla="routerLinkActive"
				[routerLink]="[link.path]"
				variant="primary"
				class="nav-item"
				[class.hover-popup-indicator]="i === (hoverPopupMenuIndex$ | async)"
				[attr.expanded]="rla.isActive === true ? true : null"
				[attr.emphasis]="rla.isActive ? 'selected' : 'default'"
				[matMenuTriggerFor]="subLinkMenu"
				[matMenuTriggerData]="{
					parentLink: link,
					links: routeConfigSub(link),
					index: i
				}"
				#menuTrigger="matMenuTrigger"
				(mouseenter)="routeConfigSub(link).length > 0 && !rla.isActive ? enterBarMenu(menuTrigger, i) : null"
				(mouseleave)="leaveBarMenu()"
				[visBusy]="routesService.isLoading(link.data?.absoluteUrl)"
			>
				<vis-icon slot="icon" [name]="link.data?.icon" *ngIf="link.data?.icon; else noicon" size="l"></vis-icon>

				<ng-template #noicon>
					<span slot="icon" *ngIf="link.data && link.data.title">{{ link.data.title.substr(0, 2).toUpperCase() }}</span>
				</ng-template>

				<span>
					{{ link.data?.title ?? '' | transloco }}
				</span>
			</zui-menubar-nav-item>

			<ng-container *ngFor="let sublink of routeConfigSub(link)">
				<zui-menubar-nav-item
					class="nav-item-sub"
					variant="primary"
					[routerLink]="['./' + link.path + '/' + sublink.path]"
					routerLinkActive
					#rlaSub="routerLinkActive"
					[attr.emphasis]="rlaSub.isActive ? 'selected-primary' : 'selected'"
					[attr.expanded]="rla.isActive === true ? true : null"
					[visTooltip]="sublink.data?.description ?? '' | transloco"
					[id]="rlaSub.isActive === true ? 'navBarItemActive' : null"
				>
					<div slot="icon" [style.marginLeft]="$any(menubar).opened ? 'calc(var(--zui-gu) * 3)' : ''">
						<vis-icon size="m" [name]="sublink.data?.icon!" *ngIf="sublink.data?.icon; else noicon"></vis-icon>

						<ng-template #noicon>
							<span slot="icon" *ngIf="sublink.data && sublink.data.title">{{
								sublink.data.title.substr(0, 2).toUpperCase()
							}}</span>
						</ng-template>
					</div>

					<span [style.marginLeft]="$any(menubar).opened ? 'calc(var(--zui-gu) * 1.5)' : ''">
						{{ sublink.data?.title || '' | transloco }}
					</span>
				</zui-menubar-nav-item>
			</ng-container>

			<zui-menubar-divider *ngIf="i < (routesService.authorizedRoutes$ | async).length - 1"></zui-menubar-divider>
		</ng-container>
	</zui-menubar>

	<div
		class="manual-toggle-button cursor-pointer"
		(click)="toggleMenubarExpanded()"
		[class.opened]="$any(menubar).opened"
	>
		<vis-icon
			[name]="
				$any(menubar).opened
					? 'zui-icon-arrow-outline-arrow-outline-visually-centered-left'
					: 'zui-icon-arrow-outline-arrow-outline-visually-centered-right'
			"
		></vis-icon>
	</div>

	<mat-menu #subLinkMenu="matMenu" hasBackdrop="false" overlapTrigger="true" [class]="popupPanelClass">
		<ng-template matMenuContent let-links="links" let-parentLink="parentLink" let-index="index">
			<div (mouseleave)="leavePopupMenu()" (mouseenter)="enterPopupMenu(index)">
				<div
					class="popup-menu-item"
					*ngFor="let link of links"
					[routerLink]="['./' + parentLink.path + '/' + link.path]"
					[visBusy]="routesService.isLoading(link.data.absoluteUrl)"
				>
					<vis-icon slot="icon" [name]="link.data?.icon" *ngIf="link.data?.icon; else noicon" size="m"></vis-icon>
					<ng-template #noicon>
						<span slot="icon" *ngIf="link.data && link.data.title">{{
							link.data.title.substr(0, 2).toUpperCase()
						}}</span>
					</ng-template>
					<div>{{ link.data?.title ?? '' | transloco }}</div>
				</div>
			</div>
		</ng-template>
	</mat-menu>
</ng-container>
