{
	"signIn": "Sign in",
	"home": "Home",
	"tutorial": {
		"app": {
			"welcome": "Welcome",
			"welcomeDesc": "Welcome to {{ appName }}! <br><br>Here is a tutorial to <b>guide you through this application</b>. You can easily close it by tapping anywhere outside."
		},
		"bookmark": {
			"add": "Add a bookmark",
			"addSpecific": "Add {{ titlePrefix }} - {{ title }} to your bookmarks",
			"addSpecificByClick": "Add <i>{{ titlePrefix }} - {{ title }}</i> to your bookmarks by clicking this icon. <br><br>Bookmarks are getting <b>pinned to the start page</b>.<br>You'll get to the start page by clicking the <b>app logo</b> at the top left.",
			"all": "All bookmarks",
			"bookmarks": "Bookmarks",
			"pinnedSpecific": "Pinned <b>{{ url }}</b> to the start page",
			"removeSpecific": "Remove {{ titlePrefix }} - {{ title }} from your bookmarks",
			"sectionDescription": "These are your bookmarks. <br><br>Remove bookmarks by clicking the <b>icon at the top right</b>."
		}
	},
	"warnings": {
		"languageNotSupported": "This area does not support your preferred language ({{ currentLanguage }}). Instead, the default language ({{ defaultLanguage }}) is being used",
		"sessionTimedOut": "Session timed out",
		"sessionTimedOutDesc": "Your session has timed out. Please sign in again."
	},
	"accessHelp": "Need help with access?",
	"proceed": "Proceed",
	"cancel": "Cancel",
	"auth": {
		"title": "Auth",
		"desc": "Auth module",
		"routes": {
			"permissions": {
				"title": "Permissions",
				"desc": "Manage accounts, roles and rules"
			}
		}
	},
	"ddp": {
		"routes": {
			"title": "DDP",
			"desc": "Tools for delivery date prediction",
			"cutoffTime": {
				"title": "Cutoff Time",
				"desc": "Manage cutoff time"
			},
			"shift": {
				"title": "Shifts",
				"desc": "Manage shifts"
			},
			"communicationStatus": {
				"title": "Communication Status",
				"desc": "Manage communication status"
			},
			"statusLocalActivityType": {
				"title": "Status Local Activity Type",
				"desc": "Manage status local activity type map"
			},
			"statusLocalLane": {
				"title": "Status Local Lane",
				"desc": "Manage status local lane map"
			},
			"metadata": {
				"title": "Metadata",
				"desc": "Manage metadata"
			}
		}
	},
	"omr": {
		"title": "Order Management",
		"desc": "Tools for P0 Order Management & Routing.",
		"routes": {
			"oct": {
				"desc": "Views and manages the whole order processing lifecycle",
				"title": "Order Clearing Tool",
				"initial": {
					"title": "Order search",
					"desc": "Search by order information"
				},
				"rxData": {
					"title": "Rx Pass search",
					"desc": "Search by Rx Pass information"
				},
				"buildPurchaseOrder": {
					"title": "Build Purchase Order Search",
					"desc": "Build Purchase Order Search"
				},
				"fileSearch": {
					"title": "File Search",
					"desc": "Search and download files linked to an order"
				},
				"zeebe": {
					"title": "BPMN",
					"desc": "Business Process Model and Notation (BPMN)"
				},
				"copo": {
					"title": "COPO Search",
					"desc": "Call Off Purchase Search"
				}
			},
			"rxPass": {
				"title": "Manual review - Rx Pass",
				"desc": "Manages Lens Pass Orders"
			},
			"esbConfiguration": {
				"title": "ESB Configuration",
				"desc": "View and manage the ESB configuration",
				"targetSystems": {
					"title": "Target Systems"
				},
				"statusRouting": {
					"title": "VCA Status Routing"
				}
			},
			"statusManagement": {
				"title": "Status Management",
				"desc": "View and manage OMR statuses"
			},
			"orderErrors": {
				"title": "Error Handling",
				"desc": "Overview orders error"
			},
			"errorMasterData": {
				"title": "Error Handling Master Data",
				"desc": "Manage error master data"
			},
			"transferPrices": {
				"title": "Transfer Prices",
				"desc": "Overview SAP BW Transfer Price Identification"
			},
			"outOfStock": {
				"title": "Out of stock Orders",
				"desc": "Individualized Rx Products which are out of stock "
			},
			"blockedOrders": {
				"title": "Blocked orders",
				"desc": "Overview of Blocked Orders"
			}
		}
	},
	"admin": {
		"title": "Administration",
		"desc": "Tools for managing users.",
		"routes": {
			"announcement": {
				"desc": "Manage announcements.",
				"title": "Announcements"
			}
		}
	},
	"logs": {
		"title": "System logs"
	},
	"lpp": {
		"title": "LPP",
		"desc": "Lenspass Portal",
		"routes": {
			"mgmt": {
				"desc": "Lenspass Management",
				"title": "Management"
			}
		}
	},
	"settings": {
		"title": "Settings"
	},
	"status": {
		"title": "Status management",
		"desc": "Tools for managing system statuses.",
		"routes": {
			"statusmap": {
				"desc": "Assign local to global status.",
				"title": "Map"
			},
			"statustext": {
				"desc": "Maintain the texts of the local system states.",
				"title": "Texts"
			}
		}
	},
	"tasks": {
		"title": "Tasks"
	},
	"documentation": {
		"routes": {
			"title": "Documentation",
			"desc": "Documentation overview",
			"api": {
				"desc": "Overview of API documentation",
				"title": "API"
			},
			"db": {
				"desc": "Overview of database documentation",
				"title": "Database"
			},
			"package": {
				"desc": "Overview of package documentation",
				"title": "Package"
			}
		}
	},
	"cim": {
		"routes": {
			"title": "CIM",
			"desc": "Manage and organize the inventory",
			"movements": {
				"title": "Material Movements",
				"desc": "Search and manage material movements"
			},
			"inventory": {
				"title": "Material Inventory",
				"desc": "Search and filter the current inventory"
			},
			"casepackMapping": {
				"title": "Casepack Mapping",
				"desc": "Search and inspect casepacks"
			},
			"reservations": {
				"title": "Reservations",
				"desc": "Manage inventory reservations"
			},
			"safetyStock": {
				"title": "Safety Stock",
				"desc": "Manage safety stock"
			},
			"errorHandling": {
				"title": "Error Handling",
				"desc": "Manage occurred errors"
			},
			"analytics": {
				"title": "Analyse Movements",
				"desc": "Analyse Material movements",
				"movements": {
					"title": "Material Movements",
					"desc": "Material Movements & Material Details"
				}
			}
		}
	},
	"tst": {
		"title": "TST",
		"desc": "Managing of shipments",
		"routes": {
			"config": {
				"desc": "Configuring of the workspace",
				"title": "Configuration",
				"tutorial": "In this area you can configure your individual settings"
			},
			"handover": {
				"desc": "Handing over of shipments",
				"title": "Shipment handover",
				"tutorial": ""
			},
			"packingList": {
				"desc": "Creating a shipment",
				"title": "Create shipment",
				"tutorial": "In this area you can create a new shipment , process the packaging including printing of needed supplements and enter the carrier shipment reference"
			},
			"search": {
				"desc": "Searching for shipments",
				"title": "Shipment search",
				"tutorial": "In this area you can search for any shipment by using different filter"
			},
			"sorting": {
				"desc": "Sorting of shipments",
				"title": "Shipment sorting",
				"tutorial": ""
			},
			"scanNavigation": {
				"desc": "To navigate between the tabs use the arrows",
				"title": "Navigation"
			},
			"scanNavigationCreateShipment": {
				"desc": "You can create a new shipment by pressing the plus button",
				"title": "Create shipment"
			},
			"scanNavigationLoadShipment": {
				"desc": "You can load an existing shipment by entering its shipment id and press the enter plus button",
				"title": "Load shipment"
			},
			"scanLoadShipment": {
				"desc": "You can load an existing shipment by scanning its shipment id",
				"title": "Scan shipment"
			},
			"deleteShipment": {
				"desc": "You can delete an existing shipment by pressing the bin button",
				"title": "Delete shipment"
			},
			"packaging": {
				"desc": "In the packaging tab, you can create a packaging structure with handling units to afterwards add the shipment items to the handling units",
				"title": "Add handling unit"
			},
			"packagingTree": {
				"desc": "In the packaging structure tree, you can see and manage the packaging structure.</br></br> By selecting a handling unit in the tree the item list on the right side gets filtered and you can add items into the selected handling unit.</br> At the right side of each handling unit you have a few options: You can close or (re)open a handling unit by clicking the lock.</br></br> If any supplements need to be printed a dialog will pop up guiding the printout.</br></br> You can also move or remove one or more handling units to/from an outer handling unit (so called parent) by clicking the … symbol of the wanted outer handling unit. Therefore a dialog will pop up where you can scan the inner handling units. And you also have the option to delete a handling unit by clicking the red bin.",
				"title": "Packaging structure"
			},
			"packagingScanHandlingUnit": {
				"desc": "You can create an individual packaging structure. For a new handling unit scan a handling unit type id or choose a type and press the enter plus button",
				"title": "Scan handling unit type"
			},
			"shipmentScanShipment": {
				"desc": "By scanning a serial number, the shipment item will be added to the list",
				"title": "Scan shipment items"
			},
			"shipmentFilterShipment": {
				"desc": "You can filter for a specific item by typing any characters that are contained in the data you are looking for",
				"title": "Filter shipment items"
			},
			"carrier": {
				"desc": "In this view, the carrier is selected. The preferred carrier is already set as default; however, this can also be changed. Optional you can  enter the shipment reference of the carrier",
				"title": "Carrier"
			},
			"carrierSave": {
				"desc": "You need to save your changes by pressing the save button",
				"title": "Save carrier"
			},
			"searchList": {
				"desc": "In this view you can find all shipments",
				"title": "Shipment result"
			},
			"searchFilter": {
				"desc": "By using the filter on the left side, you can search for specific shipments",
				"title": "Shipment filter"
			},
			"searchPaging": {
				"desc": "On the right side all shipments for your specific filter settings are listed. You can change the number of items per page or turn to the next page to see all items",
				"title": "Shipment paging"
			},
			"shipmentOverview": {
				"desc": "In each window, the shipment overview is shown on the left. You can close this overview by pressing the blue arrow",
				"title": "Shipment overview"
			},
			"searchOptions": {
				"desc": "At the very right of the shipments, you have different actions you can process with an individual shipment. You can go to the shipment to see all its details and continue the shipment preparation process, hand the shipment over to the carrier or delete the shipment",
				"title": "Shipment options"
			},
			"handoverSortingScan": {
				"desc": "By scanning the sorting code you can find all shipments with that sorting code that are ready for handover",
				"title": "Scan sorting code"
			},
			"handoverCarrier": {
				"desc": "By scanning all their carrier shipment references they are marked to be handed over",
				"title": "Scan carrier"
			},
			"handoverNavigation": {
				"desc": "After scanning or manually selecting the shipments you want to handover, the system wise handover can be done by pressing this button",
				"title": "Handover"
			},
			"handlingUnitsClick": {
				"desc": "By clicking on a handling unit you can filter to the corresponding supplements or shipment items",
				"title": "Handling unit"
			},
			"relabel": {
				"desc": "Relabelling",
				"title": "Relabelling",
				"tutorial": ""
			},
			"mobile": {
				"desc": "Mobile",
				"title": "Mobile",
				"handover": {
					"desc": "Handover of shipments",
					"title": "Handover (mobile)"
				},
				"packaging": {
					"desc": "Packaging of shipments",
					"title": "Packaging (mobile)"
				}
			},
			"problems": {
				"desc": "Shipment problems overview",
				"title": "Shipment problems",
				"tutorial": ""
			}
		}
	},
	"gss": {
		"title": "GSS",
		"desc": "Manage, organize and configure Supplements.",
		"search": {
			"navigate": "Go to:"
		},
		"routes": {
			"adminConfig": {
				"desc": "Configure templates for usage as admin.",
				"title": "Configure Template"
			},
			"adminselect": {
				"desc": "Open AdminUI for approving and/or setting default master template.",
				"title": "Set Default Master Template"
			},
			"admintest": {
				"desc": "Build and run test cases for GSS services.",
				"title": "Test GSS Services"
			},
			"comConfig": {
				"desc": "Configure templates for usage.",
				"title": "Configure Template"
			},
			"comselect": {
				"desc": "Open ComUI to create a new template.",
				"title": "Create New Template"
			},
			"dataservice": {
				"desc": "Manage available data for GSS features and templates.",
				"title": "Data Service"
			},
			"designer": {
				"desc": "Design and create a fully new template.",
				"title": "Template Designer"
			},
			"kpis": {
				"desc": "Get an overview about the GSS KPIs.",
				"title": "Analytics Board"
			},
			"utilsjasperpdf": {
				"desc": "Convert a Base64 string into Jasper PDF file.",
				"title": "Base64 to Jasper PDF"
			}
		}
	},
	"gssdesigner": {
		"title": "GSS Web designer",
		"routes": {
			"selectSupplement": {
				"desc": "Open Web Designer for create template",
				"title": "Create Template"
			},
			"selectTemplate": {
				"desc": "Open Web Designer for edit template",
				"title": "Edit Template"
			}
		}
	},
	"vpDev": {
		"routes": {
			"title": "Development",
			"desc": "Tools for product owners and developers",
			"pullrequest": {
				"title": "Features",
				"desc": "Overview of active or completed features or bug fixes"
			},
			"wiki": {
				"title": "Wiki",
				"desc": "Overview of internal wiki articles"
			},
			"esbUi": {
				"title": "ESB UI",
				"desc": "Overview of APIs, user details, users and groups"
			},
			"repos": {
				"title": "DevOps Repositories",
				"desc": "Overview of DevOps repositories tracked by the Vision Portal"
			},
			"servicemanager": {
				"title": "Service Manager",
				"desc": "Overview of all microservices and their status"
			},
			"iconsOverview": {
				"title": "Icons Overview",
				"desc": "Overview of usable icons"
			}
		}
	},
	"mddb": {
		"routes": {
			"title": "MDDB",
			"desc": "Data of the <span class=\"vis-nowrap\">ZEISS Vision Care</span> machine park and the administration of this data.",
			"createMaster": {
				"desc": "Create Master",
				"title": "Create Master"
			},
			"createObject": {
				"desc": "Create Object",
				"title": "Create Object"
			},
			"detailObject": {
				"desc": "Edit Object",
				"title": "View Object"
			},
			"editMaster": {
				"desc": "Maintain Masters",
				"title": "Edit Master"
			},
			"editObject": {
				"desc": "Edit Object",
				"title": "Edit Object"
			},
			"maintenance": {
				"desc": " ",
				"title": "Maintenance"
			},
			"master": {
				"desc": "Maintain Masters",
				"title": "Master"
			},
			"meta": {
				"desc": "Maintain Metadata",
				"title": "Metadata"
			},
			"object": {
				"desc": "Maintain Objects",
				"title": "Object"
			},
			"operatingData": {
				"desc": "Maintain Operating Data",
				"title": "Operating Data"
			},
			"pcp": {
				"desc": " ",
				"title": "PCP"
			},
			"qrCode": {
				"desc": " ",
				"title": "QR-Code"
			},
			"qrCodeScan": {
				"desc": "Scan MDDB QR-Codes directly from the Vision Portal",
				"title": "QR-Code Scanner"
			},
			"viewMaster": {
				"desc": "Maintain Masters",
				"title": "View Master"
			},
			"viewObject": {
				"desc": "Edit Object",
				"title": "View Object"
			},
			"wiki": {
				"desc": "Get Started with MDDB",
				"title": "Wiki"
			},
			"print": {
				"desc": "Print labels for multiple objects",
				"title": "Bulk printing"
			}
		}
	},
	"gbp": {
		"routes": {
			"title": "GBP",
			"desc": "Manage global business partners",
			"organization": {
				"desc": "Manage organizations data",
				"title": "Organizations"
			},
			"individual": {
				"desc": "Manage individuals data",
				"title": "Individuals"
			},
			"manualBroadcast": {
				"desc": "Manual broadcast",
				"title": "Manual Broadcast"
			},
			"metadata": {
				"desc": "Manage metadata",
				"title": "Metadata"
			}
		}
	},
	"utils": {
		"title": "Utilities",
		"desc": "Helpers to ease standard workflows.",
		"routes": {
			"announcement": {
				"desc": "Manage Vision Portal announcements.",
				"title": "Announcement Manager"
			},
			"aksmanager": {
				"desc": "Manage Azure Kubernetes Service",
				"title": "AKS Manager"
			},
			"blobdownload": {
				"desc": "Download blobs from the LODB servers via URL or MessageID.",
				"title": "Blob Downloader"
			},
			"encode": {
				"desc": "Encode a file into Base64 or HEX string.",
				"title": "Encode/Decode File"
			},
			"invite": {
				"desc": "Send Azure Active Directory (AAD) invitations.",
				"title": "Invite AAD"
			},
			"json": {
				"desc": "Stringify or parse a JSON document.",
				"title": "JSON parse/stringify"
			},
			"msgdelete": {
				"desc": "Deleting entries from the transactional tables of the LODB.",
				"title": "Message Delete"
			},
			"msgmove": {
				"desc": "Copy orders or messages from one environment to another.",
				"title": "Message Move"
			},
			"syncGroup": {
				"desc": "Synchronize groups of database tables in the LODB between its different stages.",
				"title": "Syncgroups"
			},
			"urlshortener": {
				"desc": "Transform long and complex URLs to readable and shareable short URLs.",
				"title": "URL Shortener"
			},
			"xmlconvert": {
				"desc": "Convert XML code to LODB JSON.",
				"title": "XML Converter"
			},
			"databaseaccess": {
				"desc": "Add or remove IP addresses to database connection whitelist",
				"title": "VP DB Access"
			},
			"translations": {
				"desc": "Translate your Application",
				"title": "Translation Tool"
			},
			"settingstooloe": {
				"desc": "Order Entry Settings",
				"title": "Settings Tool OE"
			}
		}
	},
	"skillmatrix": {
		"routes": {
			"title": "Skills Matrix",
			"desc": "View and manage skills matrix",
			"skills": {
				"desc": "Skills overview",
				"title": "Skills Overview"
			},
			"metadata": {
				"desc": "Manage metadata",
				"title": "Metadata"
			}
		}
	},
	"otd": {
		"routes": {
			"title": "OTD",
			"desc": "Tools for reporting and managing OTD numbers",
			"e2ereport": {
				"desc": "Transmit weekly E2E OTD performance reports.",
				"title": "E2E Report"
			},
			"e2ereportmgmt": {
				"desc": "Transmit weekly E2E OTD performance reports.",
				"title": "E2E Report Management"
			},
			"e2eutilities": {
				"desc": "Transmit weekly E2E OTD performance reports.",
				"title": "E2E Utilities"
			},
			"rxreport": {
				"desc": "Transmit weekly E2E OTD performance reports.",
				"title": "RX Report"
			}
		}
	},
	"eva": {
		"routes": {
			"title": "eVA",
			"desc": "Data Push to eVA Platform",
			"sanitize": {
				"title": "Sanitize",
				"desc": "Sanitizer Demo"
			}
		}
	}
}
