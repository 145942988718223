import { AUTH_GUARD } from '@zeiss/ng-vis-vp-auth/injection-tokens';
import { vpRule } from '@zeiss/ng-vis-vp-auth/types';
import { RULE } from '@zeiss/ng-vis-vp-auth/constants';
const OTD_PATHS = {
  _: 'otd',
  e2ereport: 'e2e/report',
  rxreport: 'rx/report',
  e2eutilities: 'e2e/utils',
  e2ereportmgmt: 'e2e/reportmgmt',
  tasks: 'tasks'
};
const OTD_ROUTES = {
  path: OTD_PATHS._,
  canActivate: [AUTH_GUARD],
  data: {
    title: 'vp.otd.routes.title',
    description: 'vp.otd.routes.desc',
    icon: 'zui-icon-time-time-time',
    showInMenu: true,
    rule: vpRule({
      rule: RULE.access_otd,
      required: []
    }),
    routes: [{
      path: ''
    }, {
      path: OTD_PATHS.e2ereport,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'vp.otd.routes.e2ereport.title',
        description: 'vp.otd.routes.e2ereport.desc',
        icon: 'zui-icon-file-file-report-overview',
        showInMenu: true,
        cache: true,
        rule: vpRule({
          rule: RULE.access_otd,
          required: ['e2e/report']
        })
      }
    }, {
      path: OTD_PATHS.e2ereportmgmt,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'vp.otd.routes.e2ereportmgmt.title',
        description: 'vp.otd.routes.e2ereportmgmt.desc',
        icon: 'zui-icon-file-file-report-overview',
        showInMenu: true,
        cache: true,
        rule: vpRule({
          rule: RULE.access_otd,
          required: ['e2e/reportmgmt']
        })
      }
    }, {
      path: OTD_PATHS.e2eutilities,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'vp.otd.routes.e2eutilities.title',
        description: 'vp.otd.routes.e2eutilities.desc',
        icon: 'zui-icon-service',
        showInMenu: true,
        cache: true,
        rule: vpRule({
          rule: RULE.access_otd,
          required: ['e2e/utils']
        })
      }
    }, {
      path: OTD_PATHS.rxreport,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'vp.otd.routes.rxreport.title',
        description: 'vp.otd.routes.rxreport.desc',
        icon: 'zui-icon-size-distribution',
        showInMenu: true,
        cache: true,
        rule: vpRule({
          rule: RULE.access_otd,
          required: ['rx/report']
        })
      }
    }]
  }
};

/**
 * Generated bundle index. Do not edit.
 */

export { OTD_PATHS, OTD_ROUTES };
