import { RULE } from '@zeiss/ng-vis-vp-auth/constants';
import { AUTH_GUARD } from '@zeiss/ng-vis-vp-auth/injection-tokens';
import { vpRule } from '@zeiss/ng-vis-vp-auth/types';
const ROUTE_PATHS = {
  _: 'skillmatrix',
  metadata: 'metadata',
  skillsOverview: 'skills-overview'
};
const ROUTE_CONFIG = {
  path: ROUTE_PATHS._,
  canActivate: [AUTH_GUARD],
  data: {
    title: 'vp.skillmatrix.routes.title',
    description: 'vp.skillmatrix.routes.desc',
    icon: 'zui-icon-setup-qualification-measurement',
    showInMenu: true,
    rule: vpRule({
      rule: RULE.access_skillmatrix,
      required: []
    }),
    routes: [{
      path: ''
    }, {
      path: ROUTE_PATHS.skillsOverview,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'vp.skillmatrix.routes.skills.title',
        description: 'vp.skillmatrix.routes.skills.desc',
        icon: 'zui-icon-file-file-file-transfer',
        showInMenu: true,
        hierarchy: 2,
        rule: vpRule({
          rule: RULE.access_skillmatrix,
          required: ['overview']
        })
      }
    }, {
      path: ROUTE_PATHS.metadata,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'vp.skillmatrix.routes.metadata.title',
        description: 'vp.skillmatrix.routes.metadata.desc',
        icon: 'zui-icon-system-parameters',
        showInMenu: true,
        hierarchy: 1,
        rule: vpRule({
          rule: RULE.access_skillmatrix,
          required: ['metadata']
        })
      }
    }]
  }
};

/**
 * Generated bundle index. Do not edit.
 */

export { ROUTE_CONFIG, ROUTE_PATHS };
