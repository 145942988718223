import { RULE } from '@zeiss/ng-vis-vp-auth/constants';
import { AUTH_GUARD } from '@zeiss/ng-vis-vp-auth/injection-tokens';
import { vpRule } from '@zeiss/ng-vis-vp-auth/types';
const ROUTE_PATHS = {
  _: 'eva',
  sanitize: 'sanitize'
};
const ROUTE_CONFIG = {
  path: ROUTE_PATHS._,
  canActivate: [AUTH_GUARD],
  data: {
    title: 'vp.eva.routes.title',
    description: 'vp.eva.routes.desc',
    icon: 'zui-icon-connections',
    showInMenu: true,
    rule: vpRule({
      rule: RULE.access_eva,
      required: []
    }),
    routes: [{
      path: ''
    }, {
      path: ROUTE_PATHS.sanitize,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'vp.eva.routes.sanitize.title',
        description: 'vp.eva.routes.sanitize.desc',
        icon: 'zui-icon-seal',
        showInMenu: true,
        hierarchy: 2,
        rule: vpRule({
          rule: RULE.access_eva,
          required: ['sanitize']
        })
      }
    }]
  }
};

/**
 * Generated bundle index. Do not edit.
 */

export { ROUTE_CONFIG, ROUTE_PATHS };
